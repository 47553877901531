import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import stapleIllustration from '../images/staple.svg';

const ServicesPage = () => (
	<Layout>
		<SEO
			title="About"
			keywords={['staple','design','gwilym']}
		/>

		<div className="flex flex-col md:flex-row items-center">
			<div className="blog-post">

				<div className="flex mb-5">
					<div className="w-3/4 lg:w-5/6 flex items-center">
						<div>
							<h1 className="w-full">What I do</h1>
						</div>
					</div>
					<div className="w-1/4 lg:w-1/6 flex items-center">
						<img src={stapleIllustration} alt="Staple" />
					</div>
				</div>

				<p className="font-bold mt-4">
					I have found great projects have distinct stages with their own scopes and deliverables. This frames expectations in the right way and prevents over investment in the early stage of exploring an idea.
				</p>


				<h2 className="mt-3">Stage 1: Idea exploration</h2>
				<div className="text-sm mt-2 mb-3">Typical Deliverables: User personas and stories, Wireframe prototype, user test reports, technical specifications.</div>

				<p>
					Modern tools mean I can workshop with clients and very rapidly build up a working, click through, prototype of the concept. This allows for exploration of the real intention of the app and turning that into something that can be presented to shareholders and users.
				</p>
				<p>
					This stage does not include navel gazing about colours, images and fonts. Rather it cements how the app will work, how many screens there are what clicks to where and why it needs to exist.
				</p>
				<p>
					The value of testing the idea with users at this stage can not be emphasised enough. People are often very biased and convincing in person about their ideas. Testing before more investment is made often throws up things they have not considered, you can even pay online services to do this unbiased testing for you.
				</p>
				<p>
					Thinking in terms of the Minimum Viable Product (MVP) is also a strong discipline at this stage, software projects often blow out and figuring out the minimum you need to do to get established is way better than throwing the net wider than you Minimum Viable Audience (MVA) will require.
				</p>


				<h2 className="mt-3">Stage 2: Design</h2>
				<div className="text-sm mt-2 mb-3">Typical Deliverables: Screen designs for mobile and desktop, exported assets to be used in the build phase, css and/or specifications for implementing the design.</div>

				<p>
					Designers find it much easier and faster to get to grips with an idea if they have output from Stage 1 above. Their job is then to take it to a level that the implementation team will immediately be able to start building it without having to decode the intentions of the project or do any design themselves.
				</p>


				<h2 className="mt-3">Stage 3: Build</h2>
				<div className="text-sm mt-2 mb-3">Typical Deliverables: Working application deployed on the internet, documentation on how to manage, build and deploy, raw code in a version controlled repository accessible by the owner.</div>

				<p>
					I have built web sites and apps with Wordpress, Laravel, React JS, Jekyll, Gatsby and Django. If I think I do not have the chops to do something properly I bring in other talent. I strongly believe in transparency and project portability.
				</p>

			</div>

		</div>
	</Layout>
);

export default ServicesPage;
